<template>
  <div class="comp-full-calendar">
    <!-- header -->
    <!-- titleFormat：{{titleFormat}}
    <br>
    firstDay：{{firstDay}}
    <br>
    monthNames：{{monthNames}}
    <br>
    tableType：{{tableType}}
    <br>
    weekNames：{{weekNames}} -->
    <fc-header
      :current-date="currentDate"
      :title-format="titleFormat"
      :first-day="firstDay"
      :month-names="monthNames"
      :tableType="tableType"
      @change="changeDateRange">

      <div slot="header-right" class="btn-group">
        <div>
          <button :class="{cancel:tableType=='week',primary:tableType=='month'}" @click="changeType('month')">月</button>
          <button :class="{cancel:tableType=='month',primary:tableType=='week'}" @click="changeType('week')">周</button>
        </div>
      </div>
    </fc-header>
    <!-- body -->
    <fc-body
      ref="fcbody"
      :current-date="currentDate"
      :events="events"
      :month-names="monthNames"
      :tableType="tableType"
      :loading="loading"
      :week-names="weekNames"
      :first-day="firstDay"
      :weekDays="weekDays"
      @eventclick="emitEventClick"
      @dayclick="emitDayClick"
      @moreclick="emitMoreClick">
      <template slot="body-card">
        <slot name="fc-body-card"></slot>
      </template>
    </fc-body>
  </div>
</template>
<script type="text/babel">
import langSets from '@/utils/langSets'
import fcHeader from '@/components/FullCalendar/fc-header'
import fcBody from '@/components/FullCalendar/fc-body'

/*
  * events: 数据源（data）
  * lang: 日历语言（默认为中文简体）
  * firstDay: 视图第一列显示周几（默认0，显示周一）
  * titleFormat: 日期显示格式
  * monthNames：月显示格式
  * weekNames：周显示格式
*/
export default {
  components: {
    'fc-header': fcHeader,
    'fc-body': fcBody
  },
  props: {
    events: {
      type: Array,
      default: () => {
        return []
      }
    },
    lang: {
      type: String,
      default: 'en'
    },
    firstDay: {
      type: Number || String,
      validator (val) {
        const res = parseInt(val)
        return res >= 0 && res <= 6
      },
      default: 0
    },
    titleFormat: {
      type: String,
      default () {
        return langSets[this.lang].titleFormat
      }
    },
    monthNames: {
      type: Array,
      default () {
        return langSets[this.lang].monthNames
      }
    },
    weekNames: {
      type: Array,
      default () {
        const arr = langSets[this.lang].weekNames
        return arr.slice(this.firstDay).concat(arr.slice(0, this.firstDay))
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tableType: 'month',
      weekDays: [],
      currentDate: new Date()
    }
  },
  methods: {
    changeDateRange (start, end, currentStart, current, weekDays) {
      this.currentDate = current
      if (this.tableType === 'week') {
        let weekData = weekDays
        weekData.pop()
        this.weekDays = weekData
      }
      this.$emit('change', this.tableType, start, end, currentStart)
    },
    emitEventClick (event, jsEvent) {
      this.$emit('eventClick', event, jsEvent)
    },
    emitDayClick (day, jsEvent) {
      this.$emit('dayClick', day, jsEvent)
    },
    emitMoreClick (day, events, jsEvent) {
      this.$emit('moreClick', day, event, jsEvent)
    },
    changeType (type) {
      this.tableType = type
      this.$emit('changeType', type)
    }
  }
}

</script>
<style lang="scss">
.comp-full-calendar{
  min-width: 960px;
  margin:0 auto;
  ul,p{
    margin:0;
    padding:0;
    font-size: 14px;
  }
  .cancel{
    border: 0;
    outline: none;
    box-shadow: unset;
    background-color: #ECECED;
    color: #8B8F94;
    &:hover {
      color: #3E444C;
      z-index: 0;
    }
  }
  .primary{
    border: 0;
    outline: none;
    box-shadow: unset;
    // background-color: #5272FF;
    background-color: #f5c319;
    color: #fff;
    &:hover{
      z-index: 0;
    }
  }
  .btn-group{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    button{
      width: 80px;
      cursor: pointer;
      height: 26px;
    }
  }
}
</style>
