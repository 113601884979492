var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "full-calendar-header" }, [
    _c("div", { staticClass: "header-left" }, [_vm._t("header-left")], 2),
    _c("div", { staticClass: "header-center" }, [
      _c(
        "span",
        {
          staticClass: "prev-month",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.goPrev.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.leftArrow))]
      ),
      _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
      _c(
        "span",
        {
          staticClass: "next-month",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.goNext.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.rightArrow))]
      ),
    ]),
    _c("div", { staticClass: "header-right" }, [_vm._t("header-right")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }