var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comp-full-calendar" },
    [
      _c(
        "fc-header",
        {
          attrs: {
            "current-date": _vm.currentDate,
            "title-format": _vm.titleFormat,
            "first-day": _vm.firstDay,
            "month-names": _vm.monthNames,
            tableType: _vm.tableType,
          },
          on: { change: _vm.changeDateRange },
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group",
              attrs: { slot: "header-right" },
              slot: "header-right",
            },
            [
              _c("div", [
                _c(
                  "button",
                  {
                    class: {
                      cancel: _vm.tableType == "week",
                      primary: _vm.tableType == "month",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("month")
                      },
                    },
                  },
                  [_vm._v("月")]
                ),
                _c(
                  "button",
                  {
                    class: {
                      cancel: _vm.tableType == "month",
                      primary: _vm.tableType == "week",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.changeType("week")
                      },
                    },
                  },
                  [_vm._v("周")]
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "fc-body",
        {
          ref: "fcbody",
          attrs: {
            "current-date": _vm.currentDate,
            events: _vm.events,
            "month-names": _vm.monthNames,
            tableType: _vm.tableType,
            loading: _vm.loading,
            "week-names": _vm.weekNames,
            "first-day": _vm.firstDay,
            weekDays: _vm.weekDays,
          },
          on: {
            eventclick: _vm.emitEventClick,
            dayclick: _vm.emitDayClick,
            moreclick: _vm.emitMoreClick,
          },
        },
        [_c("template", { slot: "body-card" }, [_vm._t("fc-body-card")], 2)],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }